<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" lg="12">
        <base-material-chart-card
          :data="orderByDayChart.data"
          :options="orderByDayChart.options"
          :responsive-options="orderByDayChart.responsiveOptions"
          color="#E91E63"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info"> mdi-refresh </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Statistic Of Orders / Day
          </h4>

          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock </v-icon>
            <span class="caption grey--text font-weight-light">Just updated</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" lg="12">
        <base-material-card color="success" icon="mdi-store" title="Net Sales Revenue">
          <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="chartDataProfitNeto"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
          />
          <h4 class="card-title font-weight-light mt-2 ml-2"></h4>

          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock </v-icon>
            <span class="caption grey--text font-weight-light">Just updated</span>
          </template>
        </base-material-card>
      </v-col>

      <v-col cols="12" lg="12">
        <base-material-card
          color="info"
          icon="mdi-clipboard-flow"
          title="Cash Flow Performance"
        >
          <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="chartCashFlowData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
          />
          <h4 class="card-title font-weight-light mt-2 ml-2"></h4>

          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock </v-icon>
            <span class="caption grey--text font-weight-light">Just updated</span>
          </template>
        </base-material-card>
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="info"
          icon="mdi-poll"
          title="Categories Registered"
          :value="totalCategories"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="info"
          icon="mdi-poll"
          title="Product Registered"
          :value="totalProducts"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          title="Net Income"
          :value="totalProfit"
          sub-icon="mdi-calendar"
          sub-text="Last 24 Hours"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="orange"
          icon="mdi-store"
          title="Gross Income"
          :value="totalOrders"
          sub-icon="mdi-calendar"
          sub-text="Last 24 Hours"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="6">
        <base-material-stats-card
          color="info"
          icon="mdi-clipboard-flow"
          title="Cash Flow"
          :value="totalCashflow"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="6">
        <base-material-stats-card
          color="info"
          icon="mdi-checkbook"
          title="Valuation Report"
          :value="totalValuations"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col cols="12" md="6">
        <base-material-card color="info" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Sales based on market platforms</div>

            <div class="subtitle-1 font-weight-light">
              Income from online sales in Indonesia
            </div>
          </template>
          <v-card-text>
            <v-data-table ref="vDataTable" :headers="headers" :items="ordersMarketPlace">
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="6">
        <base-material-card color="success" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Net Income / Quarter</div>

            <div class="subtitle-1 font-weight-light">
              Net Income / Quarter from online sales in Indonesia
            </div>
          </template>
          <v-card-text>
            <v-data-table
              ref="vDataTable"
              :headers="headersQuartal"
              :items="ordersProfitQuartal"
            />
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <template>
      <v-layout row justify-center v-if="!guest">
        <v-dialog v-model="progressBar" persistent max-width="290" id="progressBar">
          <v-progress-linear indeterminate color="blue" height="30"
            ><span>Waits...</span></v-progress-linear
          >
        </v-dialog>
      </v-layout>
    </template>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
export default {
  name: "DashboardDashboard",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 270,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartDataProfitNeto: {
        labels: [],
        datasets: [
          {
            label: "Net Revenue",
            backgroundColor: "green",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      totalCategories: "",
      totalProducts: "",
      totalProfit: "",
      totalCashflow: "",
      totalOrders: "",
      totalValuations: "",
      chartCashFlowData: {
        labels: [],
        datasets: [
          {
            label: "Cash Flow",
            backgroundColor: "blue",
            data: [],
          },
        ],
      },
      orderByDayChart: {
        data: {
          labels: [],
          series: [[]],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      ordersMarketPlace: [],
      headers: [
        {
          sortable: false,
          text: "Total",
          value: "id",
        },
        {
          sortable: false,
          text: "Price Total",
          value: "total_bill",
        },
        {
          sortable: false,
          text: "Market Place",
          value: "payment_card_number",
        },
      ],
      ordersProfitQuartal: [],
      headersQuartal: [
        { text: "QUARTER", value: "kuartal" },
        { text: "YEAR", value: "tahun" },
        { text: "REVENUE", value: "total_pendapatan" },
      ],
      progressBar: false,
    };
  },
  created() {
    this.countCategories();
    this.countProducts();
    this.countProfit();
    this.countCashflows();
    this.countOrders();
    this.countValuation();
    this.countOrderByDay();
    this.countMarketPlace();
    this.countProfitQuartal();
    this.goProfitNeto();
    this.graph();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      guest: "auth/guest",
    }),
  },
  methods: {
    graph() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/cashflow/graph";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          for (var i in data) {
            this.chartCashFlowData.datasets[0].data.push(data[i].total);
            this.chartCashFlowData.labels.push(data[i].created_at);
          }
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    goProfitNeto() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/orders/all-profit-neto";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          for (var i in data) {
            this.chartDataProfitNeto.datasets[0].data.push(data[i].total_neto);
            this.chartDataProfitNeto.labels.push(data[i].YEARMONTH_neto);
          }
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    countCategories() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/admin/count-categories";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.totalCategories = data[0].countCategories;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    countProducts() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/admin/count-products";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.totalProducts = data[0].countProducts;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    countProfit() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/admin/count-profit";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.totalProfit = data[0].total;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    countCashflows() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/admin/count-cashflows";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.totalCashflow = data[0].saldo;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    countOrders() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/admin/count-order";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.totalOrders = data[0].totalOrder;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    countValuation() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/admin/count-valuation";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.totalValuations = data[0].countValuation;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    countOrderByDay() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/orders/count-orders-day";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;

          for (var i in data) {
            this.orderByDayChart.data.series[0].push(data[i].count_order);
            this.orderByDayChart.data.labels.push(data[i].day);
          }
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },

    countMarketPlace() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/orders/count-market-place";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.ordersMarketPlace = data;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    countProfitQuartal() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/orders/count-profit-quartal";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.ordersProfitQuartal = data;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    goProfitNeto() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/orders/all-profit-neto";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          for (var i in data) {
            this.chartDataProfitNeto.datasets[0].data.push(data[i].total_neto);
            this.chartDataProfitNeto.labels.push(data[i].YEARMONTH_neto);
          }
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
  },
};
</script>
